<template>
	<div>
		<page-header
			:title=" partner ? `${partner?.owner.fname} ${partner?.owner.lname}` : ''"
			pre-title="VEHICLE PARTNERS OVERVIEW"
			:loading="false">
		</page-header>
		<div class="mx-4 p-1">
			<p>
				<router-link to="/partners" class="pay-nav">Vehicle Partners<img src="@/assets/img/caret-right.svg" alt=""></router-link>
				<router-link :to="{ name:'PartnerDetails', params:{accountSid: partner?.account_sid, partnerId: partner?.id }}" class="pay-nav">{{partner?.owner.fname}} {{ partner?.owner.lname }}<img src="@/assets/img/caret-right.svg" alt=""></router-link>
				Past payouts
			</p>
		</div>
		<div class="card mx-4">
			<div class="card-header justify-content-between">
				<div>
					<fieldset class="d-flex flex-row" id="group1">
						<div class="d-flex align-items-center">
							<input
								v-model="statusFilter"
								class="form-control list-search"
								type="radio"
								name="group1"
								value="payout"
								@click="getPastPayout()"
								/>
							<label class="m-2">Payout</label>
						</div>
						<div class="d-flex align-items-center">
							<input
								v-model="statusFilter"
								class="form-control list-search"
								type="radio"
								name="group1"
								value="deduction"
								@click="getPastDeductions()"
								/>
							<label class="m-2">Deductions</label>
						</div>
					</fieldset>
				</div>
				<div class="col-3">
					<v-datepicker
						style="width: 100%"
						v-model="datePicker"
						placeholder="This month"
						range
						value-type="format"
						format="YYYY-MM-DD"
						type="date"
						@change="getPastPayout()"
						></v-datepicker>
				</div>
			</div>
			<b-table striped hover selectable responsive show-empty :items="pastPayout" :fields="fields"
				:per-page="pageSize" :current-page="currentPage" @row-clicked="onRowClicked" :busy="fetchPayout">
				<template #table-busy>
					<div class="d-flex flex-column justify-content-center align-items-center">
						<div class="spinner-border" role="status"></div>
						<p class="text-center mt-2"><strong>Loading...</strong></p>
					</div>
				</template>
				<template #empty>
					<p class="text-center text-secondary">No records available</p>
				</template>
				<template #cell(payoutPeriod)="data">
					{{ data.item.periodStart }} - {{ data.item.periodEnd }}
				</template>
			</b-table>
			<div class="card-footer" v-if="totalRecords">
				<div class="row align-items-center">
					<div class="col">
						<span class="text-dark font-weight-bold">{{ tablePageText }}</span>
					</div>
					<div class="col-auto">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRecords"
							:per-page="pageSize"></b-pagination>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import PageHeader from '@/components/layout/PageHeader'
import TableView from '@/mixins/tableview'

export default {
  name: 'PastPartnerPayout',
  components: { PageHeader },
  mixins: [TableView],
  data () {
    return {
      statusFilter: 'payout',
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      datePicker: '',
      id: parseInt(this.$route.params.id),
      fields: [
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'time',
          label: 'Time'
        },
        {
          key: 'payoutPeriod',
          label: 'Payout Period'
        },
        {
          key: 'bankName',
          label: 'Bank Name'
        },
        {
          key: 'accountNumber',
          label: 'Account Number'
        }
      ],
      partner: null,
      pastPayout: [],
      fetchPayout: true
    }
  },
  methods: {
    onRowClicked (payout) {
      this.$router.push({
        name: 'PayoutSummary',
        params: {
          earningId: payout.earningId,
          id: this.id,
          start: payout.periodStart,
          end: payout.periodEnd
        }
      })
    },
    async getPartner () {
      try {
        const res = await this.axios.get(`v1/partners/${this.id}`)
        if (res.status == 200) {
          this.partner = res.data
        }
      } catch (err) {
        
      } finally {
        this.getPastPayout()
      }
    },
    async getPastPayout () {
      this.fetchPayout = true
      const startDate = this.datePicker[0]
      const endDate = this.datePicker[1]

      let params = '?page=' + 1 + '&perPage=' + 10

      if (startDate != null && startDate != undefined) {
        params += '&startDate=' + startDate
      }
      if (endDate != null && endDate != undefined) {
        params += '&endDate=' + endDate
      }

      try {
        const res = await this.axios.get(`cost-revenue/v1/partners/${this.partner.account_sid}/payouts` + params)
        if (res.status == 200) {
          const r = res.data.result
          r.forEach((item) => {
            const e = new Date(item.createdAt)
            item.date = e.toDateString()
            item.time = e.toLocaleTimeString()
            const x = new Date(item.periodStart)
            const y = new Date(item.periodEnd)
            const options = { month: 'short', day: 'numeric' }
            item.periodStart = x.toLocaleDateString('en-us', options)
            item.periodEnd = y.toLocaleDateString('en-us', options)
          })
          this.pastPayout = r
        }
      } catch (err) {
        
      } finally {
        this.fetchPayout = false
      }
    },
    async getPastDeductions () {
      this.fetchPayout = true
      // let startDate = this.datePicker[0]
      // let endDate = this.datePicker[1]

      const params = '&page=' + 1 + '&perPage=' + 20 + '&sort=' + 'createdAt:desc'

      // if(startDate != null && startDate != undefined){
      //     params += "&startDate=" + startDate
      // }
      // if(endDate != null && endDate != undefined){
      //     params += "&endDate=" + endDate
      // }

      try {
        const res = await this.axios.get(`cost-revenue/v1/deductions?partnerId=${this.partner.account_sid}` + params)
        if (res.status == 200) {
          const r = res.data.result
          r.forEach((item) => {
            const e = new Date(item.createdAt)
            item.date = e.toDateString()
            item.time = e.toLocaleTimeString()
          })
          this.pastPayout = r
        }
      } catch (err) {
        
      } finally {
        this.fetchPayout = false
      }
    }
  },
  created () {
    this.getPartner()
  }

}
</script>

<style lang="scss" scoped>
.pay-nav {
    color: #B9BCC8;
}

</style>
